.telephone-prefix {
    width: 150px;
    display: inline-block;
    float: left;
}

.telephone-prefix-number {
    width: calc(100% - 170px);
    display: inline-block;
    float: right;
}

.telephone-number-wrapper {
    clear: both;
    overflow: hidden;
}

@media only screen and (max-width: 479px) {
    .telephone-prefix, .telephone-prefix-number {
        width: 100%;
    }
}